import React from "react"

const MediaItem = ({pageContext: {item}}) => {
  return (
    <div>
      <div id="id">{item.id}</div>
      <div id="title">{item.title}</div>
      <div id="body">{item.body}</div>
      <div id="url">{item.url}</div>
      <div id="domain">{item.domain}</div>
      <div id="siteName">{item.siteName}</div>
      <div id="image">{item.image}</div>
      <div id="collection">{item.collection}</div>
      <div id="release-date">{item.releaseDate}</div>
      <div id="fileType">{item.fileType}</div>
      <div id="type">{item.type}</div>
    </div>
  )
}

export default MediaItem;